

























import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component({})
export default class List extends Vue {
  data = {}
  loading = true

  mounted() {
    if (this.isNew) {
      this.data = {}
    } else {
      this.loading = true
      this.$axios
        .get(this.url)
        .then((response) => {
          this.data = response.data.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          vxm.alert.onAxiosError(err)
        })
    }
  }

  save() {
    if (this.isNew) {
      this.$axios
        .post(this.url, this.data)
        .then((response) => {
          this.data = response.data.data
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.$axios
        .put(this.url, this.data)
        .then((response) => {
          this.data = response.data.data
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  cancel() {
    this.$router.back()
  }

  get isNew() {
    return !(this.$route.params.id && parseInt(this.$route.params.id) !== 0)
  }

  get baseUrl() {
    return '/v4/site/products/categories'
  }

  get url() {
    const url = this.baseUrl
    return this.isNew ? url : url + '/' + this.$route.params.id
  }
}
